import {connect, useDispatch} from 'react-redux';
import {projectIdParam} from '../FinishProject/FinishProjectForm';
import {API_URL} from '../App';
import {SET_PROJECTS} from '../../actions';

export const findProjectIdInUrl = () => {
  const params = (new URL(document.location)).searchParams;
  const projectId = params.get(projectIdParam);

  return projectId ?? null;
};

const LoadProjectFromUrl = ({projects}) => {
  const dispatch = useDispatch();

  if (projects.length > 0) {
    return null;
  }

  const projectId = findProjectIdInUrl();

  if (!projectId) {
    return null;
  }

  fetch(`${API_URL}/projects/${projectId}`, {redirect: 'follow'})
    .then((response) => response.json())
    .then((projects) => dispatch({type: SET_PROJECTS, projects}))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
    });

  return null;
};

const mapStateToProps = (state) => ({
  projects: state.app.projects,
});

// use connect to avoid unnecessary rerender
export default connect(
  mapStateToProps,
)(LoadProjectFromUrl);
