import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Rod from './Rod';
import { FENCE_TYPE_SPAN, FENCE_TYPE_GATEWAY } from 'fenceTypesConstants';

export const SPAN_MIN_SPACE = 10;
export const SPAN_SECOND_AND_BEFORE_LAST_MIN_SPACE = 20;

export const MOVE_TYPE_HOVER = 'MOVE_TYPE_HOVER';
export const MOVE_TYPE_DROP = 'MOVE_TYPE_DROP';

const prepareRods = (rods, dragIndex, hoverIndex) => {
  const dragRod = rods[dragIndex];

  let newRods = update(rods, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRod],
      ],
    },
  );

  return newRods.map(r => ({ ...r, targetNeighbor: false }));
}

const Rods = ({ updateProject, setAlert }) => {
  let heightUsed = 0;

  const project = useSelector((state) => state.app.projects.find((project) => project.id === state.app.currentProjectId));

  const [tmpRods, setTmpRods] = useState(project.rods);

  useEffect(() => setTmpRods(project.rods), [project.rods]);

  const move = (dragIndex, hoverIndex, type) => {
    const rods = prepareRods(tmpRods, dragIndex, hoverIndex);

    switch (type) {
      case MOVE_TYPE_HOVER:
        rods[hoverIndex].targetNeighbor = true;
        setTmpRods(rods);
        break;
      case MOVE_TYPE_DROP:
        updateProject({ ...project, rods });
        break;
      default:
        throw new Error('Wrong move type.');
    }
  };

  const lastIndex = project.rods.length - 1;

  const rods = tmpRods.map((rod, index) => {
    let space;

    const isLast = lastIndex === index;
    const isSecond = index === 1;
    const isBeforeLast = (index === tmpRods.length - 3);

    if (project.type === FENCE_TYPE_SPAN) {
      if (isSecond) {
        space = {
          min: SPAN_SECOND_AND_BEFORE_LAST_MIN_SPACE,
          disabled: isLast,
          space: rod.space < SPAN_SECOND_AND_BEFORE_LAST_MIN_SPACE ? SPAN_SECOND_AND_BEFORE_LAST_MIN_SPACE : rod.space,
        };
      } else if (isBeforeLast) {
        space = {
          min: SPAN_SECOND_AND_BEFORE_LAST_MIN_SPACE,
          disabled: isLast,
          space: rod.space < SPAN_SECOND_AND_BEFORE_LAST_MIN_SPACE ? SPAN_SECOND_AND_BEFORE_LAST_MIN_SPACE : rod.space,
        };
      } else {
      space = {
        min: isLast ? 0 : SPAN_MIN_SPACE,
        disabled: isLast,
        space: isLast ? 0 : rod.space,
      };
    }
    } else {
      space = {
        min: SPAN_MIN_SPACE,
        disabled: false,
        space: rod.space,
      };
    }

    if (index > 0) {
      heightUsed += tmpRods[index - 1].space;
    }

    const possibleMailboxRod = (project.type === FENCE_TYPE_SPAN || project.type === FENCE_TYPE_GATEWAY)
      && index > 0
      && (tmpRods[index - 1].size === 80 || tmpRods[index - 1].size === 100)
      && tmpRods[index - 1].space >= 10
      && tmpRods[index - 1].space <= 20
      && (rod.size === 80 || rod.size === 100)
      && project.heightExpected - heightUsed > 500;

    heightUsed += rod.size;

    return (
      <Rod
        key={rod.id}
        id={rod.id}
        rodForMachine={rod.rodForMachine || false}
        possibleMailboxRod={possibleMailboxRod}
        mailboxPlacement={project.mailboxPlacement || null}
        rodForMailbox={project.mailbox && possibleMailboxRod ? (rod.rodForMailbox || false) : false}
        index={index}
        move={move}
        size={rod.size}
        project={project}
        updateProject={updateProject}
        setAlert={setAlert}
        space={space}
        targetNeighbor={rod.targetNeighbor}
      />
    );
  });

  return <DndProvider backend={HTML5Backend}>
    {rods}
  </DndProvider>
};

Rods.propTypes = {
  updateProject: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default Rods;
